var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            "tab-list": _vm.tabsMenu,
            bordered: false,
            "active-tab-key": _vm.activeTab
          },
          on: { tabChange: _vm.onTabChange },
          scopedSlots: _vm._u([
            {
              key: "customRender",
              fn: function(item) {
                return _c("span", {}, [_vm._v(_vm._s(_vm.$t(item.title)))])
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "tabBarExtraContent" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.storeDetailSO.salesOrderNumber || "-"))
              ]),
              _c("a-divider", { attrs: { type: "vertical" } }),
              _c("span", [_vm._v(_vm._s(_vm.storeDetailSO.status || "-"))])
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "component-fade", mode: "out-in" } },
            [_c("keep-alive", [_c(_vm.dynamicComp, { tag: "component" })], 1)],
            1
          ),
          _c(
            "a-card-grid",
            { staticClass: "w-100", attrs: { hoverable: false } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { align: "end", span: 24 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.handleBack } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                          ]),
                          _vm.hasPrivilegeSO &&
                          !_vm.isModeCreate &&
                          _vm.showBtnCancel
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    loading: _vm.loading.cancel
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelSO(_vm.salesOrderId)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_cancel")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.hasPrivilegeSO
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    loading: _vm.loading.draft,
                                    disabled: _vm.storeDetailSO && !_vm.isDraft
                                  },
                                  on: { click: _vm.handleSubmitDraft }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_save_as_draft")) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.isWaitingApproval && _vm.hasPrivilegeApproval
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    loading: _vm.loading.reject,
                                    icon: "close"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.rejectSO(_vm.salesOrderId)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_reject")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.isWaitingApproval && _vm.hasPrivilegeApproval
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    loading: _vm.loading.approve,
                                    type: "primary",
                                    icon: "check"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.approveSO(_vm.salesOrderId)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_approve")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.hasPrivilegeSOWarehouse &&
                          _vm.isWaitingForWarehouse
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.update
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateSOWarehouse(
                                        _vm.salesOrderId
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_update")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.hasPrivilegeSO
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "save",
                                    loading: _vm.loading.submit,
                                    disabled: !_vm.isModeCreate && !_vm.isDraft
                                  },
                                  on: { click: _vm.handleSubmit }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_submit")) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }