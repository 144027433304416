





































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { SALES_ORDER_STATUS } from "@/models/enums/sales-order.enum";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
import { RequestSalesOrderCreate, RequestSalesOrderUpdate, RequestSalesOrderWarehouseUpdate, ResponseDetailSalesOrder, ResponseSubmitSalesOrder } from "@interface/sales-order.interface";
import { salesOrderService } from "@service/sales-order.service";
import Vue from "vue";
import { createNamespacedHelpers, mapGetters } from "vuex";

const { mapMutations, mapState, mapActions } = createNamespacedHelpers("salesOrderStore");

const ABLE_TO_CANCEL = [
  SALES_ORDER_STATUS.DRAFT,
  SALES_ORDER_STATUS.SUBMITTED,
];

export default Vue.extend({
  name: "SalesOrderFormIndex",
  components: {
    SalesOrderForm: () => import(/*webpackPrefetch: true*/"./components/SalesOrderForm.vue"),
    SalesOrderTable: () => import(/*webpackPrefetch: true*/"./components/SalesOrderFormTable.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      tabsMenu: [
        {
          key: "customer",
          title: "lbl_customer",
          comp: "SalesOrderForm",
          disabled: false,
          scopedSlots: { tab: "customRender" },
        },
        {
          key: "product",
          title: "lbl_product",
          comp: "SalesOrderTable",
          disabled: false,
          scopedSlots: { tab: "customRender" },
        },
      ],
      activeTab: "customer",
      loading: {
        update: false,
        submit: false,
        cancel: false,
        draft: false,
        approve: false,
        reject: false,
      },
      salesOrderId: "",
    };
  },
  computed: {
    ...mapState({
      storeReqCreate: (state: any) => state.reqCreate,
      storeProductLines: (state: any) => state.productLines,
      storeDetailSO: (state: any) => state.detailSO,
      storeDeletedLine: (state: any) => state.deletedLines,
      storeFormSO: (state: any) => state.formSO,
    }),
    ...mapGetters([
      "authStore/GET_USER_PRIVILEGES"
    ]),
    showBtnCancel(): boolean {
      return ABLE_TO_CANCEL.includes(this.storeDetailSO.status);
    },
    dynamicComp(): string {
      return this.tabsMenu.find(x => x.key === this.activeTab)?.comp || "";
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    isModeDetail(): boolean {
      return this.$route.meta.mode === Mode.DETAIL;
    },
    isDraft(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.DRAFT;
    },
    isSubmitted(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.SUBMITTED;
    },
    isCancelled(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.CANCELLED;
    },
    isWaitingForWarehouse(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.WAITING_FOR_WAREHOUSE;
    },
    isWaitingApproval(): boolean {
      return this.storeDetailSO.status === SALES_ORDER_STATUS.WAIT_FOR_APPROVAL;
    },
    hasPrivilegeApproval(): boolean {
      return !!this["authStore/GET_USER_PRIVILEGES"].find((x: IAuthorities) => x.key === "approval-sales-order" && x.privilege.update);
    },
    hasPrivilegeSO(): boolean {
      return !!this["authStore/GET_USER_PRIVILEGES"].find((x: IAuthorities) => x.key === "sales-order" && x.privilege.create || x.privilege.update || x.privilege.delete);
    },
    hasPrivilegeSOWarehouse(): boolean {
      return !!this["authStore/GET_USER_PRIVILEGES"].find(x => x.key === "sales-order-warehouse" && x.privilege.update);
    },
  },
  created() {
    this.resetStore();
    if (this.$route.params.id) {
      this.salesOrderId = this.$route.params.id;
      this.getDetailSO(this.salesOrderId);
    }
  },
  methods: {
    ...mapMutations({
      storeSetReqCreate: "SET_REQUEST_CREATE",
      storeSetDetailSO: "SET_DETAIL_SO",
      storeSetDeletedLine: "SET_DELETED_LINE",
    }),
    ...mapActions({
      resetStore: "RESET_STATE",
      constructReqFromDetail: "CONSTRUCT_REQUEST_FROM_DETAIL",
    }),
    cancelSalesOrder(id: string): Promise<boolean> {
      return salesOrderService.cancelSalesOrder(id);
    },
    getDetailSalesOrder(id: string): Promise<ResponseDetailSalesOrder> {
      return salesOrderService.getDetailSalesOrder(id);
    },
    createSalesOrder(payload: RequestSalesOrderCreate): Promise<ResponseDetailSalesOrder> {
      return salesOrderService.createSalesOrder(payload);
    },
    submitSalesOrder(id: string, payload: RequestSalesOrderUpdate): Promise<ResponseSubmitSalesOrder> {
      return salesOrderService.submitSalesOrder(id, payload);
    },
    updateSalesOrder(id: string, payload: RequestSalesOrderUpdate): Promise<ResponseDetailSalesOrder> {
      return salesOrderService.updateSalesOrder(id, payload);
    },
    onTabChange(key: "customer" | "product") {
      this.activeTab = key;
    },
    setRequestUpdate(): RequestSalesOrderUpdate {
      const data: RequestSalesOrderCreate = this.storeReqCreate;
      return {...data, deletedSalesOrderLineIds: this.storeDeletedLine};
    },
    doValidate(): [boolean, string | null] {
      const data: RequestSalesOrderCreate = this.storeReqCreate;
      const { etaHour, salesType } = this.storeFormSO;
      const keys = ["jobCostingId", "creditLimit", "discountTotalInvoice", "termOfPayment", "notes"];

      // remove expire date validation based on sales type
      if (salesType !== "Reserve") keys.push("expireDate");

      if (!etaHour) return [false, "eta hour"];
      for (const key in data) {
        if (!keys.includes(key) && !data[key]) {
          return [false, key.toString()];
        }
      }
      if (!data.salesOrderLines || !data.salesOrderLines.length) return [false, "product lines"];
      const prod = data.salesOrderLines.find(x => (
        !x.condition ||
        !x.productId ||
        !x.qty ||
        !x.sellPrice ||
        !x.uomId
      ));
      if (prod) return [false, "condition, product, qty, sell price, uom"];
      return [true, null];
    },
    async cancelSO(id: string): Promise<void> {
      const confirm: boolean = await this.showConfirmation();
      if (!confirm) return;
      try {
        this.loading.cancel = true;
        await this.cancelSalesOrder(id);
        this.showSuccessMessage("notif_cancel_success");
        this.getDetailSO(id);
      } catch (error) {
        this.showErrorMessage("notif_cancel_fail");
      } finally {
        this.loading.cancel = false;
      }
    },
    async getDetailSO(id: string): Promise<void> {
      try {
        const res = await this.getDetailSalesOrder(id);
        this.salesOrderId = res.id;
        this.storeSetDetailSO(res);
        this.constructReqFromDetail();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    handleSubmit(): void {
      if (this.validateSellPrice()) {
        this.$confirm({
          title: () => this.$t("lbl_warning_title").toString(),
          content: () => this.$t("confirm_sell_price_is_under_max_base_price").toString(),
          onOk: () => this.doSubmission(),
          cancelText: this.$t("lbl_cancel").toString(),
          okText: this.$t("lbl_confirm").toString()
        });
      } else {
        this.doSubmission();
      }
    },
    doSubmission(): void {
      const [valid, key] = this.doValidate();
      if (valid) {
        this.submitSO(this.salesOrderId);
      } else {
        this.showNotifValidationError(key);
      }
    },
    doSubmissionDraft(): void {
      const [valid, key] = this.doValidate();
      if (valid) {
        this.loading.draft = true;
        if (this.salesOrderId) {
          this.updateSO(this.salesOrderId, this.setRequestUpdate());
        } else {
          this.createSO(this.storeReqCreate);
        }
      } else {
        this.showNotifValidationError(key);
      }
    },
    handleSubmitDraft(): void {
      if (this.validateSellPrice()) {
        this.$confirm({
          title: () => this.$t("lbl_warning_title").toString(),
          content: () => this.$t("confirm_sell_price_is_under_max_base_price").toString(),
          onOk: () => this.doSubmissionDraft(),
          cancelText: this.$t("lbl_cancel").toString(),
          okText: this.$t("lbl_confirm").toString()
        });
      } else {
        this.doSubmissionDraft();
      }
    },
    validateSellPrice(): boolean {
      return !!this.storeReqCreate.salesOrderLines.find(x => x.sellPrice < x.bottomPrice);
    },
    async updateSO(id: string, payload: RequestSalesOrderUpdate, submit?: boolean): Promise<void> {
      try {
        this.loading.submit = true;
        await this.updateSalesOrder(id, payload);
        this.showSuccessMessage("notif_update_success");
        this.getDetailSO(id);
      } catch (error) {
        this.showErrorMessage("notif_update_fail");
      } finally {
        this.loading.submit = false;
        this.loading.draft = false;
      }
    },
    async createSO(payload: RequestSalesOrderCreate, submit?: boolean): Promise<void> {
      try {
        this.loading.submit = true;
        const res = await this.createSalesOrder(payload);
        this.salesOrderId = res.id;
        this.showSubmitSuccessMesssage();
        this.$router.replace({ name: "sales.transactionsales.salesorder.edit", params: { id: res.id } });
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loading.submit = false;
        this.loading.draft = false;
      }
    },
    async submitSO(id: string): Promise<void> {
      try {
        this.loading.submit = true;
        // create new SO if new
        if (!id && this.isModeCreate) {
          this.handleCreateSubmit();
        } else {
          // submit if update
          await this.submitSalesOrder(id, this.setRequestUpdate());
          this.getDetailSO(id);
          this.showSubmitSuccessMesssage();
        }
        this.salesOrderId = id;
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loading.submit = false;
      }
    },
    async handleCreateSubmit(): Promise<void> {
      try {
        const res = await this.createSalesOrder(this.storeReqCreate);
        const detail = await this.getDetailSalesOrder(res.id);
        this.salesOrderId = res.id;
        this.storeSetDetailSO(detail);
        this.constructReqFromDetail();
        await this.submitSalesOrder(res.id, this.setRequestUpdate());
        this.$router.replace({ name: "sales.transactionsales.salesorder.edit", params: { id: res.id } });
        this.showSubmitSuccessMesssage();
      } catch (error) {
        this.showSubmitFailedMesssage();
      }
    },
    async rejectSO(id: string): Promise<void> {
      const confirm: boolean = await this.showConfirmation();
      if (!confirm) return;
      try {
        this.loading.reject = true;
        await salesOrderService.rejectSalesOrder(id);
        this.showUpdateSuccessMesssage();
        this.getDetailSO(id);
      } catch (error) {
        this.showUpdateFailedMesssage();
      } finally {
        this.loading.reject = false;
      }
    },
    async approveSO(id: string): Promise<void> {
      try {
        this.loading.approve = true;
        await salesOrderService.approveSalesOrder(id);
        this.showUpdateSuccessMesssage();
        this.getDetailSO(id);
      } catch (error) {
        this.showUpdateFailedMesssage();
      } finally {
        this.loading.approve = false;
      }
    },
    async updateSOWarehouse(id: string): Promise<void> {
      try {
        this.loading.update = true;
        const payload = this.setRequestSalesOrderUpdateWarehouse();
        await salesOrderService.updateSalesOrderWarehouse(id, payload);
        this.showUpdateSuccessMesssage();
        this.getDetailSO(id);
      } catch (error) {
        this.showUpdateFailedMesssage();
      } finally {
        this.loading.update = false;
      }
    },
    setRequestSalesOrderUpdateWarehouse(): RequestSalesOrderWarehouseUpdate {
      return {
        salesOrderLines: this.storeReqCreate.salesOrderLines.map(x => ({
          id: x.id,
          batchDTOS: x.batchDTOS,
        })),
      };
    },
    handleBack(): void {
      this.$router.push({ name: "sales.transactionsales.salesorder"});
    }
  }
});
